<template>
  <view-item title="新车辆维修报表">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <div :style="{ height: `${height - 55}px` }">
            <div class="h-full">
              <form-query :method="customer.table[customer.mode]" :model="customer.form.data">
                <en-form-item label="统计类型">
                  <en-checkbox v-model="customer.mode" true-label="daily" false-label="daily">按日统计</en-checkbox>
                  <en-checkbox v-model="customer.mode" true-label="monthly" false-label="monthly">按月统计</en-checkbox>
                </en-form-item>
             
                <en-form-item label="结算日期" v-if="customer.mode === 'daily'">
                  <en-date-picker
                    v-model:start="customer.form.data.settlementStartDate"
                    v-model:end="customer.form.data.settlementEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                    clearable
                    multiple
                  ></en-date-picker>
                </en-form-item>

                <en-form-item label="结算日期" v-if="customer.mode === 'monthly'">
                  <en-date-picker
                    :disabled="customer.form.data.status === 'RP'"
                    v-model:start="customer.form.data.settlementStartMonth"
                    v-model:end="customer.form.data.settlementEndMonth"
                    type="monthrange"
                    value-format="YYYY-MM"
                    clearable
                    multiples
                  ></en-date-picker>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                  v-if="customer.mode === 'daily'"
                    code="NVHSVCFLD"
                    :height="height"
                    :data="customer.table.data"
                    :loading="customer.table.loading"
                    :paging="customer.table.paging"
                    show-summary
                    :summary="customer.table.summary.data"
                    :method="customer.table.daily"
                    pagination
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                  <table-dynamic
                  v-if="customer.mode === 'monthly'"
                    code="NVHSVCFLD"
                    :height="height"
                    :data="customer.table.data"
                    :loading="customer.table.loading"
                    :paging="customer.table.paging"
                    show-summary
                    :summary="customer.table.summary.data"
                    :method="customer.table.monthly"
                    pagination
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                </template>
                
              </flex-box>
            </div>
          </div>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { omit, pick } from 'lodash-es'
import { calculator } from '@enocloud/utils'
import { type TableColumnCtx } from '@enocloud/components'
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/service/vehicle/new/daily',
                params(params) {
                  params.payload = omit(this.customer.form.data, 'mode')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/service/vehicle/new/monthly',
                params(params) {
                  params.payload = omit(this.customer.form.data, 'mode')
                }
              }
            },
            click() {
              if (this.customer.form.data.mode === 'daily') this.operation.export.daily()
              if (this.customer.form.data.mode === 'monthly') this.operation.export.monthly()
              this.store.openDownload()
            }
          }
        }
      },

      customer: {
        mode: 'daily' as 'daily' | 'monthly',
        children: {
          form: {
            is: 'form',
            data: {
            
              branchIds: [],
              status: 'PD',
              newVehicleOnly: true,
              settlementStartDate: dayjs().format('YYYY-MM-DD'),
              settlementEndDate: dayjs().format('YYYY-MM-DD'),
              settlementStartMonth: dayjs().format('YYYY-MM'),
              settlementEndMonth: dayjs().format('YYYY-MM'),
              checked: true
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/service/daily',
                summary: 'GET /enocloud/statement/service/summary',
                params(params) {
                  params.payload = pick(this.customer.form.data, [
                    'mode',
                    'branchIds',
                    'status',
                    'newVehicleOnly',
                    'settlementStartDate',
                    'settlementEndDate',
                    'settlementStartMonth',
                    'settlementEndMonth'
                  ])
                },
                data: 'array',
                loading: true,
                pagination: true
              },
              monthly: {
                action: 'GET /enocloud/statement/service/monthly',
                summary: 'GET /enocloud/statement/service/summary',
                params(params) {
                  params.payload = pick(this.customer.form.data, [
                    'mode',
                    'branchIds',
                    'status',
                    'newVehicleOnly',

                    'settlementStartMonth',
                    'settlementEndMonth'
                  ])
                },
                data: 'array',
                loading: true,
                pagination: true
              }
            },
            summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
              columns: TableColumnCtx<T>[]
              data: T[]
            }) {
              return ctx.columns
                .map((item) => item.property)
                .map((key, index) => {
                  if (['count', 'serviceReceivableAmount', 'totalValueBeforeDiscount', 'serviceProfit'].includes(key)) {
                    return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
                  } else return !index ? '合计' : '-'
                })
            }
          }
        }
      }
    }
  },

  mounted() {
    this.customer.table.daily()

  }
})
</script>
